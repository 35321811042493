import React from "react";
import ReactDOM from "react-dom";
import Grid from "../components/CenteredGrid";

const pageTitle = <h1> Explore </h1>;

export default function Explore() {
  return (
    <div>
      <Grid props={pageTitle} />
    </div>
  );
}

ReactDOM.render(Explore, document.getElementById("root"));
