import React from "react";
import "../css/header.scss";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import { blockStatement } from "@babel/types";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.menuHandler = this.menuHandler.bind(this);
    this.menuCloseHandler = this.menuCloseHandler.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      display: "block",
      width: window.innerWidth,
      menu: "block"
    };
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  updateDimensions = () => {
    // console.log("Page Dimensions are " + this.state.width);
    if (this.state.width <= 550) {
      this.setState({
        display: "none",
        width: window.innerWidth
      });
    } else {
      this.setState({
        display: "block",
        width: window.innerWidth
      });
    }
  };
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {}

  menuHandler() {
    this.setState(prevState => {
      if (prevState.display === "none") {
        return {
          display: "block",
          menu: "none"
        };
      }
    });
  }

  menuCloseHandler(event) {
    this.setState(prevState => {
      if (
        (prevState.display === "block" || this.state.display === "block") &&
        !(this.state.width > 550)
      ) {
        return {
          display: "none",
          menu: "block"
        };
      }
    });
  }

  render() {
    return (
      <div className="navbar">
        <div ref={this.setWrapperRef}>{this.props.children} </div>
        <div className="header">
          <span>
            <label id="burger-menu" htmlFor="toggle">
              &#9776;
            </label>
            <input type="button" id="toggle" onClick={this.menuHandler} />
          </span>
          <nav
            className="topnav"
            style={{ display: this.state.display }}
            onClick={this.menuCloseHandler}
          >
            <div className="menu-img-container">
              <img
                src={require("../images/daniel-enc.jpg")}
                id="dan-img"
                alt="Daniel Encarnacion"
              />
            </div>
            <ul className="nav-links">
              <Link to="/" className="button">
                <li id="home-logo">
                  <i className="far fa-home"></i>
                </li>
              </Link>
              <Link to="/contact" className="button">
                <li id="contact-logo">
                  <i className="far fa-paper-plane"></i>
                </li>
              </Link>
              <Link to="/about" className="button">
                {/* <li>About</li> */}
              </Link>
              <Link to="/explore" className="button">
                {/* <li>Explore</li> */}
              </Link>
              <Link to="/blog" className="button">
                {/* <li>Blog</li> */}
              </Link>
            </ul>
            <div className="menu-footer">
              <p>2020 Daniel Encarnacion &copy;</p>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Header;
