import React from "react";
import Grid from "../components/CenteredGrid";
import $ from "jquery";
import "../css/about.scss";
//eslint-disable-next-line
import img from "../images/daniel-enc-podium.png";

// const AboutContent = (

// );

export default class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0
    };
  }

  componentDidMount() {
    var current = 1; //keeps track of the current div
    var height = $(".roles").height(); //the height of the roles div
    var numberDivs = $(".roles").children().length; //the number of children of the roles div
    var first = $(".roles div:nth-child(1)"); //the first div nested in roles div
    setInterval(function() {
      var number = current * -height;
      first.css("margin-top", number + "px");
      if (current === numberDivs) {
        first.css("margin-top", "0px");
        current = 1;
      } else current++;
    }, 1500);
  }

  render() {
    return (
      <Grid
        props={
          <div className="container">
            {/* <h1>About Me</h1> */}
            <div className="left-child">
              <div className="wrapper">
                <div className="roles">
                  <div>I am Analytical</div>
                  <div>I am Creative</div>
                  <div>I'm an Entrepreneur</div>
                  <div>I'm a Leader</div>
                </div>
              </div>
            </div>
            <div className="right-child">
              {/* <table>
                <tr>
                  <th>Education</th>
                </tr>
                <td>Lehman College</td>
                <td>2020</td>

                <tr>
                  <th>Experience</th>
                  <th>Year</th>
                </tr>
                <tr>
                  <td>Pfizer</td>
                  <td>2018</td>
                </tr>
                <tr>
                  <td>ListenFirst Media</td>
                  <td>2020</td>
                </tr>
                <tr>
                  <td>Zillow Group</td>
                  <td>2020</td>
                </tr>
                <tr>
                  <th>Languages</th>
                  <th>Frameworks</th>
                </tr>

                <tr>
                  <td>Javascript</td>
                  <td>React.js</td>
                </tr>
                <tr>
                  <td>Python</td>
                  <td>Django</td>
                </tr>
                <tr>
                  <td>Node.js</td>
                  <td>Express</td>
                </tr>
                <tr>HTML5</tr>
                <tr>CSS3</tr>
              </table> */}
            </div>
          </div>
        }
      />
    );
  }
}
