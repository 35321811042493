import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // borderRadius: 3,
    // border: 0,
    // color: 'white',
    // height: 48
    // padding: "0 auto"
    // position: "relative",
    // top: "50%"
    // left: "50%",
    margin: 1

    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    margin: "10px 0px 25px 0px",
    // padding: 15,
    background: "rgba(255, 255, 255, 0)",
    // color: "#000",
    minWidth: "90%",
    minHeight: "75vh",
    fontFamily: 'font-family: "Open Sans Condensed", sans-serif',
    letterSpacing: "0.08em",
    // borderRadius: 20,
    borderStyle: "hidden",
    boxShadow: "none",
    textField: {
      variant: "outlined"
    },
    outlinedInput: {
      "&$focused $notchedOutline": {
        border: "hidden"
      }
    }
  }
}));

// eslint-disable-next-line
export default function CenteredGrid({ props, secondProps }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={7}>
        <Paper className={classes.paper}>{secondProps}</Paper>
        </Grid>
        <Grid item xs={12} sm={5}>
          {props}
        </Grid>
      </Grid>
    </div>
  );
}
