import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line
import MultiPanel from "../components/MultiPanelGrid";
import "../css/blog.scss";

export default function Blog() {
  return (
    <div>{/* <MultiPanel props={"Blog"} secondProps={"Daniel"} /> */}</div>
  );
}

ReactDOM.render(Blog, document.getElementById("root"));
