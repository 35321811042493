import React from "react";
import Grid from "../components/CenteredGrid";
import "../css/contact.scss";
import Social from "../components/Social-Icons";
import axios from "axios";

const thanksMsg = (
  <div className="contact-success-page">
    <h2>
      {" "}
      Thank you for the note{" "}
      <span role="img" aria-label="smiley">
        &#128516;
      </span>
    </h2>
    <p>I will work to get back to you as quickly as possible! </p>
  </div>
);

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.formSubmit = this.formSubmit.bind(this);
    this.nameHandler = this.nameHandler.bind(this);
    this.emailHandler = this.emailHandler.bind(this);
    this.messageHandler = this.messageHandler.bind(this);
    this.errorToDisplay = this.errorToDisplay.bind(this);

    this.state = {
      name: "FULL NAME",
      email: "EMAIL",
      message: "MESSAGE",
      backgroundColor: "",
      sent: false,
      buttonText: "SEND MESSAGE",
      display: "block",
      formErrors: {
        nameErr:
          "Please provide your full name, must be longer than 3 characters",
        emailErr: "Email address is invalid, please enter a valid email.",
        messageErr: "Message field must be longer than 5 characters."
      },
      displayedErrors: [],
      nameValid: false,
      emailValid: false,
      messageValid: false,
      validForm: false,
      pageMessage: "GET IN TOUCH"
    };
  }

  nameHandler(e) {
    e.target.value = "";
  }
  emailHandler(e) {
    e.target.value = "";
  }
  messageHandler(e) {
    e.target.value = "";
  }

  handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let objCheck = {
      field: fieldName,
      val: value
    };

    let properLength = false;
    let patternMatch = false;

    if (objCheck.field === "email") {
      if (objCheck.val.length <= 4) {
      } else {
        properLength = true;
      }
      if (!objCheck.val.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      } else {
        patternMatch = true;
      }
      if (properLength && patternMatch) {
        this.setState(
          () => {
            return {
              emailValid: true
            };
          },
          () => {}
        );
      } else if (!properLength || !patternMatch) {
        this.setState(
          () => {
            return {
              emailValid: false
            };
          },
          () => {}
        );
      }
    }

    if (objCheck.field === "name") {
      if (objCheck.val.length <= 2) {
        properLength = false;
        if (!properLength) {
          this.setState(
            () => {
              return {
                nameValid: false
              };
            },
            () => {}
          );
        }
      } else {
        properLength = true;
        if (properLength) {
          this.setState(
            () => {
              return {
                nameValid: true
              };
            },
            () => {}
          );
        }
      }
    }

    if (objCheck.field === "message") {
      if (objCheck.val.length <= 4) {
        properLength = false;
        if (!properLength) {
          this.setState(
            () => {
              return {
                messageValid: false
              };
            },
            () => {}
          );
        }
      } else {
        properLength = true;
        if (properLength) {
          this.setState(
            () => {
              return {
                messageValid: true
              };
            },
            () => {}
          );
        }
      }
    }
  }

  validateForm() {
    if (
      this.state.nameValid &&
      this.state.emailValid &&
      this.state.messageValid
    ) {
      this.setState(
        () => {
          return {
            validForm: true,
            displayedErrors: []
          };
        },
        () => {}
      );
      return true;
    } else {
      return false;
    }
  }

  errorToDisplay() {
    if ((!this.state.emailValid) || (!this.state.displayedErrors.includes("Message field must be longer than 5 characters."))) {
      this.state.displayedErrors.push(this.state.formErrors.emailErr);
    } 
    if (!this.state.messageValid) {
      this.state.displayedErrors.push(this.state.formErrors.messageErr);
    }
    if (!this.state.nameValid) {
      this.state.displayedErrors.push(this.state.formErrors.nameErr);
    }
    setTimeout(() => {
      this.resetForm("SEND MESSAGE");
    }, 3000);
    console.log(this.state.displayedErrors);
    console.log(this.state.displayedErrors.length);
  }

  formSubmit = e => {
    e.preventDefault();
    let valid = this.validateForm();

    this.setState({
      buttonText: "...sending"
    });

    if (valid) {
      let data = {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message
      };
      axios
        .post("/.netlify/functions/server", data)
        .then(res => {
          this.resetForm("MESSAGE SENT");
          this.setState({
            sent: true,
            display: "none",
            pageMessage: thanksMsg
          });
        })
        .catch(() => {
          alert("The message could not be sent, please try again later");
          setTimeout(() => {
            window.location.replace("/");
          }, 500);
        });
    } else {
      this.errorToDisplay();
    }
  };

  resetForm = res => {
    let name = this.state.nameValid;
    let email = this.state.emailValid;
    let message = this.state.messageValid;

    this.setState({
      name: name ? this.state.name : "",
      message: message ? this.state.message : "",
      email: email ? this.state.email : "",
      buttonText: res
    });
  };

  reset = e => {
    e.target.value = thanksMsg;
  };

  render() {
    return (
      <Grid
        props={
          <div className="contact-form">
            <h1>
              <span>{this.state.pageMessage}</span>
            </h1>
            <div style={{ overflow: "auto" }}>
              {this.state.displayedErrors.map((el, index) => (
                <h4 key={index} style={{ display: this.state.display }}>
                  *Error: {el}
                </h4>
              ))}
            </div>
            <form
              action="POST "
              id="form"
              className="topBefore"
              style={{ display: this.state.display }}
              data-netlify="true"
            >
              <input
                id="name"
                type="text"
                placeholder="FULL NAME"
                name="name"
                value={this.state.name}
                onFocus={this.nameHandler}
                onChange={this.handleInputChange}
                required
              />
              <input
                id="email"
                type="text"
                name="email"
                placeholder="E-MAIL"
                value={this.state.email}
                onFocus={this.emailHandler}
                onChange={this.handleInputChange}
                required
              />
              <textarea
                id="message"
                type="text"
                placeholder="MESSAGE"
                name="message"
                value={this.state.message}
                onFocus={this.messageHandler}
                onChange={this.handleInputChange}
                required
              ></textarea>
              <button
                id="submit"
                type="submit"
                // value={this.state.buttonText}
                onClick={this.formSubmit}
              >
                {this.state.buttonText}
              </button>

              {/* </input> */}
            </form>
            <p>You can also contact me through my social media:</p>
            <Social />
          </div>
        }
      />
    );
  }
}
export default function Contact() {
  return (
    <div>
      <ContactForm />
    </div>
  );
}
