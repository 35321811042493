import React from "react";
import "../css/social.scss";
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";

export default class Social extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      facebook: "https://www.facebook.com/daniel.enc",
      twitter: "https://www.twitter.com/dan_encarn",
      linkedin: "https://www.linkedin.com/in/daniel-encarnacion/",
      instagram: "https://www.instagram.com/dan_encr"
    };
  }
  render() {
    return (
      <div className="social">
        <ul>
          <li
            className="facebook"
            value="facebook"
            onClick={e => (window.location = this.state.facebook)}
          ></li>

          <li
            className="twitter"
            value="twitter"
            onClick={e => (window.location = this.state.twitter)}
          ></li>

          <li
            className="linkedin"
            value="linkedin"
            onClick={e => (window.location = this.state.linkedin)}
          ></li>

          <li
            className="instagram"
            value="instagram"
            onClick={e => (window.location = this.state.instagram)}
          ></li>
        </ul>
      </div>
    );
  }
}
