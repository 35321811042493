import React from "react";
import "../css/footer.scss";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <p>
          <small>2020 Daniel Encarnacion &copy;</small>
        </p>
      </footer>
    );
  }
}
export default Footer;
