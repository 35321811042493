import React from "react";
import Grid from "../components/CenteredGrid";
import "../css/home.scss";
import Social from "../components/Social-Icons";

let name = "Daniel.";
const coloredNameTheme = <span className="name-theme">{name}</span>;

const bio = (
  <div className="bio">
    <p>
      I'm a computer science student at Lehman College and a former intern at
      Zillow Group, ListenFirst Media, and Pfizer Inc.
      As a software engineer, my focus is on creating full-stack applications that are
      scalable and intuitive. As a kid from The Bronx, I am deeply
      invested in increasing the number of minorities in tech, and I am open to any
      opportunities that may help to advance that cause.
    </p>
    <p>Reach out if you're interested in working together.</p>
  </div>
);
//

const homeGreeting = (
  <div className="bio-content">
    <h1 className="title">Hi, I am {coloredNameTheme}</h1>
    {bio}
    <Social />
  </div>
);

export default function Home() {
  return <Grid props={homeGreeting} />;
}
