import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    margin: "10px 10px 0px 10px",
    "@media (max-width:550px)": {
      // eslint-disable-line no-useless-computed-key
      margin: "-25px 10px 0px 10px"
    },
    background: "rgba(0, 0, 0, 0)",
    minWidth: "90%",
    minHeight: "75vh",
    fontFamily: 'font-family: "Open Sans Condensed", sans-serif',
    letterSpacing: "0.08em",
    borderStyle: "hidden",
    boxShadow: "none",
    textField: {
      variant: "outlined"
    },
    outlinedInput: {
      "&$focused $notchedOutline": {
        border: "hidden"
      }
    }
  }
}));

// eslint-disable-next-line
export default function CenteredGrid({ props }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>{props}</Paper>
        </Grid>
      </Grid>
    </div>
  );
}
