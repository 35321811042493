import React from "react";
import Grid from "../components/CenteredGrid";

const ErrorMessage = (
  <div className="error-page-message">
    
  </div>
);

export default function PageNotFound() {
  return <Grid props={ErrorMessage} />;
}
