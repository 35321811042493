import React from "react";
import "./css/App.scss";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import Home from "./views/Home";
import Contact from "./views/Contact";
import Explore from "./views/Explore";
import Blog from "./views/Blog";
import About from "./views/About";
import PageNotFound from "./views/PageNotFound";

function App() {
  return (
    <div className="content">
      <Router>
        <div className="router">
          <Header />
          <Switch>
            <Route path="/" exact component={Home}></Route>
            <Route path="/contact" component={Contact}></Route>
            <Route path="/explore" component={Explore}></Route>
            <Route path="/about" component={About}></Route>
            <Route path="/blog" component={Blog}></Route>
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
